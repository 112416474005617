import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Katalogi-Dokumenty-Certyfikaty/About'
import Tiles from 'components/layout/Sections/Pages/Katalogi-Dokumenty-Certyfikaty/CertyfikatyTiles'
import ZoomedModalImg from 'components/shared/ZoomedModalImg'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Certyfikaty i referencje',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Certyfikaty i referencje',
    },
  ],
}

const CertyfikatyIReferencjePage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.certyfikatyIReferencje
  const PAGE_SEO = data?.wpPage?.seo

  const [zoomedItemNumber, setZoomedItemNumber] = React.useState(0)

  const handleZoom = (itemNumber: number) => {
    setZoomedItemNumber(itemNumber)
  }

  const CERTIFICATES = React.useMemo(
    () =>
      PAGE?.certificatesList?.map((certificate) => ({
        img: certificate?.certificatesListCertificateImg?.localFile
          ?.childImageSharp?.gatsbyImageData,
        alt: certificate?.certificatesListCertificateImg?.altText,
        downloadLink: certificate?.certificatesListCertificateLink,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.certificatesImg?.localFile?.childImageSharp.gatsbyImageData
        }
        imgDesktop={
          PAGE?.certificatesImg?.localFile?.childImageSharp.gatsbyImageData
        }
        headingMobile={PAGE?.certificatesTitle}
        headingDesktop={PAGE?.certificatesTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.certificatesDescription} />
      <Tiles catalogues={CERTIFICATES} onZoom={handleZoom} />
      <ZoomedModalImg
        img={CERTIFICATES[zoomedItemNumber].img}
        alt="certificate"
      />
    </Layout>
  )
}

export default CertyfikatyIReferencjePage

export const query = graphql`
  query CertyfikatyIReferencjePage {
    wpPage(slug: { regex: "/certyfikaty-i-referencje/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      certyfikatyIReferencje {
        certificatesTitle
        certificatesDescription
        certificatesImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        certificatesList {
          certificatesListCertificateLink
          certificatesListCertificateImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
