import React, { useContext } from 'react'
import styled from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'
import ModalContext from 'contexts/modalContext'

type CataloguesItem = {
  img: ImageDataLike
  alt: string
  downloadLink: string
}

type Props = {
  catalogues: CataloguesItem[]
  onZoom: (itemNumber: number) => void
}

const Section = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 20px 0 0 0;

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    max-width: ${({ theme }) =>
      `calc(${theme.container.widthXL} + (${theme.container.padding} * 2))`};
    margin: 0 auto;
    padding-left: ${({ theme }) => theme.container.paddingMD};
    padding-right: ${({ theme }) => theme.container.paddingMD};
    padding-bottom: 40px;
  }
`

const TileSingleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  height: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.gray100};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray};
  }

  // Gatsby image wrapper
  div {
    max-height: 100%;
  }

  border-bottom: 3px solid #cecece;

  ${({ theme }) => theme.media.lg.min} {
    width: 100%;

    &:nth-child(odd) {
      border-bottom: 3px solid #cecece;
      border-right: 3px solid #cecece;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
`

const Tiles: React.FC<Props> = ({ catalogues, onZoom }) => {
  const { showModal } = useContext(ModalContext)
  const { lg } = useBreakpoint()

  return (
    <Section>
      <TilesWrapper>
        {catalogues.map(({ img, alt, downloadLink }, index) => (
          <TileSingleWrapper
            key={`${index}-${alt}`}
            onClick={() => {
              onZoom(index)
              showModal()
            }}
          >
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <LazyImage
                src={img}
                alt={alt}
                objectFit={lg ? 'contain' : 'cover'}
              />
            </a>
          </TileSingleWrapper>
        ))}
      </TilesWrapper>
    </Section>
  )
}

export default Tiles
